// create an Observer instance
const resizeObserver = new ResizeObserver(entries => {
    if (window.innerWidth > document.body.clientWidth) {
        document.body.classList.add("has-scroll");
    }
    else {
        document.body.classList.remove("has-scroll");
    }
})
// start observing a DOM node
resizeObserver.observe(document.body);

let env = 'dev';
let zohoBool = false;

let $zoho = $zoho || {};
$zoho.salesiq = $zoho.salesiq || {
    widgetcode: "494d841d28dfb37357496be7dc8f3b036483edb01e5ae637b0c8a265a292a958a930d77a6b71ea818c1eb197cfb5aade",
    values: {},
    ready: function () { },
};
let d = document;
let s = d.createElement("script");
s.type = "text/javascript";
s.id = "zsiqscript";
s.defer = true;
s.src = "https://salesiq.zoho.com/widget";
let t = d.getElementsByTagName("script")[0];
t.parentNode.insertBefore(s, t);

$zoho.salesiq.ready = function () {
    zohoBool = true;
};
$zoho.salesiq.ready = function () {
    zohoBool = true;
};

function add(x) {
    let email = "info@matensa.com";
    if (x.AccountEmail != 'null' && x.AccountEmail != '') {
        email = x.AccountEmail
    }
    $zoho.salesiq.visitor.email(email);
    $zoho.salesiq.visitor.name(x.Name);
    $zoho.salesiq.visitor.info({
        name: x.Name,
        email: email,
        Language: "ar",
        'Matensa Id': x.MatensaId
    });
    if (lang == "ar") $zoho.salesiq.floatbutton.position(" bottomleft ");
    if (lang == "en") $zoho.salesiq.floatbutton.position(" bottomright ");
    $zoho.salesiq.language("en");
    $zoho.salesiq.visitor.id(x.Id);
    $zoho.salesiq.visitor.contactnumber(x.InternationalMobileNumber);
    $zoho.salesiq.visitor.info({ MatensaId: x.MatensaId });
    $zoho.salesiq.floatbutton.position("left");
}

